/**
 * Filter Panel
 * @description : Show filter panel for use on mobile screens
 */

window.setUpFilterPanel = function (filterPanelEl) {
    var panelId = filterPanelEl.getAttribute('data-panel-id');

    var triggerEls = Array.from(document.querySelectorAll(`.js-filter-panel__trigger[data-panel-id='${panelId}']`));
    var closeEls = Array.from(filterPanelEl.querySelectorAll('.js-filter-panel__close'));
    var pageEls = Array.from(filterPanelEl.querySelectorAll('.js-filter-panel__page'));
    var pageButtonEls = Array.from(filterPanelEl.querySelectorAll('.js-filter-panel__page-button'));

    triggerEls.forEach(function (triggerEl) {
        triggerEl.addEventListener('click', function () {
            filterPanelEl.toggleAttribute('hidden');
        });
    });

    pageButtonEls.forEach(function (buttonEl) {
        var pageIndexString = buttonEl.getAttribute('data-page-index');
        var pairedPageEl = pageEls.find((pageEl) => pageEl.getAttribute('data-page-index') == pageIndexString);

        var pageIndex = parseInt(pageIndexString);

        buttonEl.addEventListener('click', function () {
            pageEls.forEach((pageEl) => pageEl.removeAttribute('data-swipe-dir'));
            var currentlyActivePageEl = pageEls.find((pageEl) => pageEl.classList.contains('is-active'));
            var currentlyActivePageIndex = currentlyActivePageEl ? parseInt(currentlyActivePageEl.getAttribute('data-page-index')) : 0;
            var swipeDir = currentlyActivePageIndex <= pageIndex ? "left" : "right";

            if (currentlyActivePageEl) {
                currentlyActivePageEl.setAttribute('data-swipe-dir', swipeDir);
                currentlyActivePageEl.classList.remove('is-active');
            }
            if (pairedPageEl) {
                pairedPageEl.setAttribute('data-swipe-dir', swipeDir);
                pairedPageEl.classList.add('is-active');
            }
        });
    });

    closeEls.forEach(function (closeEl) {
        closeEl.addEventListener('click', function () {
            filterPanelEl.toggleAttribute('hidden', true);
        });
    });
};

(function () {

    var filterPanelEls = Array.from(document.querySelectorAll('.js-filter-panel'));
    filterPanelEls.forEach((filterPanelEl) => window.setUpFilterPanel(filterPanelEl));

})();